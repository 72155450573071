FeedsApp.Views.Step4PreviewWindow = Backbone.View.extend({

	initialize: function() {
		var self = this;
        var target_url = "/assets/js/feeds/feed-builder/templates/preview-window.html";
		$.ajax({
			url: target_url,
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('.template-preview-container').append(self.$el);
                self.render();
                $('.template-select').trigger('change');
                self.listenTo(self.model, 'change reset add remove', self.render, self);
			},
            cache:true
		});
	},

	render: function() {
		var jobFieldsString = this.createJobFieldsString();
        var globalFieldsString = this.createGlobalFieldsString();

        var schema = {
            job_template_definition: '',
            template_definition: ''
        };

        if (FeedsApp.Variables.GlobalDefinition) {
            schema.job_template_definition = FeedsApp.Variables.GlobalDefinition.job_template_definition.replace(/{{fieldsString}}/i, jobFieldsString);
            schema.template_definition = FeedsApp.Variables.GlobalDefinition.template_definition.replace(/{{globalFieldsString}}/i, globalFieldsString);
        }
		this.$el.html(this.template(schema));
	},

	createJobFieldsString: function() {

	    var feedFields = new FeedsApp.Collections.FeedFields();

	    feedFields.add(this.model.get('job_fields').toJSON());
	    feedFields.add(this.model.get('mapped_fields').toJSON());

        return feedFields.filter(function(field) {
            return (field.get("field_type") === "job" || field.get("field_type") === "extended" || field.get('field_type') === "mapped");
        }).sort(function(a, b) {
            return a.get("applied_order") - b.get("applied_order");
        }).map(function (field) {
            var fieldName = field.get("client_field_name") || field.get("extended_field_name") || field.get("available_field").internal_name;
            return "<" + fieldName + "> {{value}} </" + fieldName + ">";
        }).join("\n");
	},

    createGlobalFieldsString: function() {
        return this.model.get('job_fields').filter(function(field) {
            return field.get("field_type") === "global"
        }).map(function (field) {
            var fieldName = field.get("client_field_name") || field.get("extended_field_name") || field.get("available_field").get("internal_name");
            var value = field.get("static_value") || '{{value}}';
            return "<" + fieldName + "> " + value + " </" + fieldName + ">";
        }).join("\n");
    }

});