MarketReportsApp.Views.ReportBuilderView = Backbone.View.extend({
	events: {
		"change .aggregate-select": "populateMetricOptions",
		// "click 	#build-report": "updatePrimaryModelViews",
		"change .report-name": "updateName",
		"change .format": "updateFormat",
		"change .org-select": "updateOrg",
		"change .primary-select": "updatePrimaryDims",
		"change .primary-select-all": "updatePrimaryDims",
		"change .secondary-select": "updateSecondaryDims",
		"change .secondary-select-all": "updateSecondaryDims",
		"change .input-daterange": "updateDates",
		"change .relative-daterange": "updateDates",
		"change [name=rangeType]": "updateRange",
		"change .schedule-select": "updateSchedule",
		"change .day-value-select": "updateScheduleOption",
		"click input[name='last-day-checkbox']": "updateScheduleOption",
		"change .post-process-schedule-select" : "updatePostProcessSchedule",
		"change .post-process-day-value-select": "updatePostProcessScheduleOption",
		"click input[name='post-process-last-day-checkbox']": "updatePostProcessScheduleOption",
		"change .user-select": "updateUsers",
		"change .skip-post-processing": "updateSkipPostProcessing",
		"click .primary-list-switch": "updatePrimaryList",
		"change .primary-dimension-lists": "updatePrimaryListSelection",
		"click .secondary-list-switch": "updateSecondaryList",
		"change .secondary-dimension-lists": "updateSecondaryListSelection",
		"click .is-standard": "updateStandard",
		"click input[name='value_axis']": "setValueAxis",
    "change .report-description": "updateDescription",
		"change .client-file-name": "updateFileName",
		"change .ftp-directory-name": "updateFTPDirectoryName",
		"click .delete-locked": "updateDeleteLocked",
		"click #metric > button": "toggleMetricStatus"
	},

	className: "app-container",

	initialize: function() {

		let self = this;

		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-wizard.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('.mr-builder-app-container').append(self.$el);
				self.render();
			},
			cache:true
		});

		MarketReportsApp.Variables.reportBuilderModel = this.model;

		MarketReportsApp.Variables.primaryModel = new MarketReportsApp.Models.ReportBuilderPrimary();

		MarketReportsApp.Variables.TotalAggregateCount = Object.keys(MarketReportsApp.Variables.Aggregates).length;
	},

	render: function() {

		this.$el.html(this.template());

		var self = this;
		$('#wizard').steps({
			bodyTag: 'section',
			enableCancelButton: false,
			stepsOrientation: "vertical",

			onStepChanging: function (event, currentIndex, newIndex) {
				if (newIndex == 0) {
					self.setFormat();
				}

				// Always allow going backward even if the current step contains invalid fields!
				if (currentIndex > newIndex)
				{
					return true;
				}

				if (currentIndex == 0) {
					self.setCompressionType();
					if (!self.setWrapSingleFile()) {
						return false;
					}
				}

				if (currentIndex == 1) { //ensuring either a list is used for primary/secondary dimensions, or else that they are hardcoded. do not allow both to be set at same time.
					self.updatePrimaryList();
					self.updateSecondaryList();
					if (!self.checkNumberOfFiles()) {
						return false;
					}
				}

				if (currentIndex == 2 && self.model.attributes.skipPostProcessing == false) {
					var appended_dimensions = [];
					var basic_stats = [];

					$('.appended-data').each(function () {
						var appended_dim = {
							reports_appended_dimensions: {
								id: $(this).find('.appended-field').val()
							}
						};
						appended_dimensions.push(appended_dim)
					});

					var postProcessId = null;
					if (MarketReportsApp.Variables.EditReport != null) {
						if (MarketReportsApp.Variables.EditReport.postProcess != null) {
							postProcessId = MarketReportsApp.Variables.EditReport.postProcess.reports_post_process_files.id;
						}

					}
					self.model.set({
						postProcessReport: {
							reports_post_process_files: {
								id: postProcessId,
								computation_id: ($('.computation').val() === "None" ? null : $('.computation').val()),
								include_primary_dimension_in_rows: $('.check-include-primary-dimension-in-rows-container').find('#include-primary-dimension-in-rows').prop('checked')
							},
							appended_dims: appended_dimensions,
							scheduler: $('.post-process-schedule-select').val(),
							schedulerOption: $('.post-process-day-value-select').val(),
							sendToUsers: $('.post-process-user-select').val(),
							with_headers: $('.with-headers').is(':checked'),
						}
					});


				}

				var form = $(this);

				// Clean up if user went backward before
				if (currentIndex < newIndex)
				{
					// To remove error styles
					$(".body:eq(" + newIndex + ") label.error", form).remove();
					$(".body:eq(" + newIndex + ") .error", form).removeClass("error");
				}

				// Disable validation on fields that are disabled or hidden.
				form.validate().settings.ignore = ":disabled,:hidden";

				// Start validation; Prevent going forward if false
				return form.valid();
			},

			onFinishing: function (event, currentIndex) {
				if (MarketReportsApp.Variables.EditReport != null) {
					if (MarketReportsApp.Variables.EditReport.reportSchedule != null) {
						self.updateExistingSchedule();
					}

					if (MarketReportsApp.Variables.EditReport.postProcessSchedule != null) {
						self.updateExistingPostProcessSchedule();
					}
				} else {
					self.model.get('postProcessReport').scheduler = $('.post-process-schedule-select').val();
					self.model.get('postProcessReport').schedulerOption = $('.post-process-day-value-select').val();
					self.model.get('postProcessReport').sendToUsers = $('.post-process-user-select').val();

				}

				$('.saving').toggleClass('hidden');
				var form = $(this);

				// Disable validation on fields that are disabled.
				// At this point it's recommended to do an overall check (mean ignoring only disabled fields)
				form.validate().settings.ignore = ":disabled";

				// Start validation; Prevent form submission if false
				return form.valid();
			},

			onFinished: function (event, currentIndex) {
				var form = $(this);

				// Submit the form
				self.model.save(null,
					{
						success: function (model, response) {
							window.location = '/market-reports/details/' + response.report_id;;
							$('.saving').toggleClass('hidden');

						},
						error: function (model, response) {
							toastr["error"](response.responseJSON.message, response.responseJSON.status);
							$('.saving').toggleClass('hidden');
						},
						parse: false
					}
				);
			}
		}).validate({
			errorPlacement: function (error, element) {
				element.before(error);
			}
		});

		this.step1View = new MarketReportsApp.Views.ReportBuilderStep1({model: this.model, parent: this});
		this.step2View = new MarketReportsApp.Views.ReportBuilderStep2({model: MarketReportsApp.Variables.primaryModel});
		this.step3View = new MarketReportsApp.Views.ReportBuilderStep3({model: this.model});
		this.step4View = new MarketReportsApp.Views.ReportBuilderStep4({model: MarketReportsApp.Variables.primaryModel});

		setInterval(function(){resizeJquerySteps()}, 250);
	},

	updateExistingSchedule: function() {
		this.model.set({scheduleId: MarketReportsApp.Variables.EditReport.reportSchedule.id});

		this.model.set({ scheduler: $('.schedule-select').val()});

		if($('input[name="last-day-checkbox"]:checked').length > 0){
			this.model.set({ schedulerOption: 'last'});
		} else {
			var selectedValue = document.getElementsByClassName("day-value-select")[0].value;
			this.model.set({ schedulerOption: selectedValue});
		}

		this.model.set({ notifyUsers: $('.user-select').val()});
	},

	updateExistingPostProcessSchedule: function() {
		var schedulerOption = null;
		if($('input[name="post-process-last-day-checkbox"]:checked').length > 0){
			schedulerOption = 'last';
		} else {
			var selectedValue = document.getElementsByClassName("post-process-day-value-select")[0].value;
			schedulerOption = selectedValue;
		}

		this.model.get('postProcessReport').scheduleId =  MarketReportsApp.Variables.EditReport.postProcessSchedule.id;
		this.model.get('postProcessReport').scheduler = $('.post-process-schedule-select').val();
		this.model.get('postProcessReport').schedulerOption = schedulerOption;
		this.model.get('postProcessReport').sendToUsers = $('.post-process-user-select').val();
	},

	populateMetricOptions: function() {

		let self = this;

		document.getElementById('metric').disabled = false;

		$('.aggregate-value-selects').addClass('hidden');

		let metricContainer = document.getElementById('metric');
		metricContainer.innerHTML = '';

		let availableMetrics = 0;
		_.filter(MarketReportsApp.Variables.Aggregates, function(agg, name) {
			return name === $('.aggregate-select').val();
		})[0].forEach(function(aggregate) {
			++availableMetrics;
			// trying not to use jquery anymore
			let metricOption = document.createElement('button');
			metricOption.setAttribute('type', 'button');
			metricOption.setAttribute('value', aggregate.aggregateId);
			metricOption.classList.add('btn', 'btn-default', 'btn-xs');
			let metricName = document.createTextNode(' ' + aggregate.metric); // name it
			let metricStatus = document.createElement('span');
			metricStatus.classList.add('glyphicon', 'glyphicon-unchecked');
			metricOption.appendChild(metricStatus);
			metricOption.appendChild(metricName);
			metricContainer.appendChild(metricOption);
		});
		if(MarketReportsApp.Variables.EditReport && !MarketReportsApp.Variables.aggregateIDs){
			let metricsByAggregate = document.querySelectorAll('#metric button');
			metricsByAggregate.forEach( function(metric) {
				if(self.model.get('aggregateIds').indexOf(metric.value) !== -1){
					metric.classList.toggle('btn-default');
					metric.classList.toggle('btn-primary');
					let clickedElement = metric.childNodes[0];
					clickedElement.classList.toggle('glyphicon-unchecked');
					clickedElement.classList.toggle('glyphicon-check');
				}
			});
			this.updatePrimaryModelViews();
		}
	},

	toggleMetricStatus: function(e) {

		e.currentTarget.classList.toggle('btn-default');
		e.currentTarget.classList.toggle('btn-primary');
		let clickedElement = e.currentTarget.childNodes[0];
		clickedElement.classList.toggle('glyphicon-unchecked');
		clickedElement.classList.toggle('glyphicon-check');

		let metricDiv = document.getElementById('metric');
		let checkedMetrics = metricDiv.getElementsByClassName('glyphicon-check');

		document.getElementById('build-report').textContent = "";

		MarketReportsApp.Variables.aggregateIDs = [];
		Array.prototype.forEach.call(checkedMetrics, function(selectedMetric) {
			MarketReportsApp.Variables.aggregateIDs.push(selectedMetric.parentElement.value);
		});
		this.model.set({aggregateIds: MarketReportsApp.Variables.aggregateIDs});

		MarketReportsApp.Variables.aggregateID = MarketReportsApp.Variables.aggregateIDs[0];
		this.model.set({aggregateId: MarketReportsApp.Variables.aggregateID});

		if (checkedMetrics.length > 0) {
			this.updatePrimaryModelViews();
		}

	},

	updatePrimaryModelViews: function (){

		$('.name-and-submit').addClass('hidden');
		$('.aggregate-value-selects').addClass('hidden');
		$('.loader').toggleClass('hidden');

		var self = this;

		if (!MarketReportsApp.Variables.EditReport) {
			self.model.set({
				primaryDimensions: [],
				secondaryDimensions: [],
				postProcessReport: {},
				skipPostProcessing: false
			});
		}

		MarketReportsApp.Variables.aggregateID = self.model.get('aggregateIds')[0];
		self.model.set({aggregateId:MarketReportsApp.Variables.aggregateID});

		MarketReportsApp.Variables.primaryModel.fetch({
			reset: true,
			success: function() {
				self.step1View.primaryView.render();
				self.step2View.render();
				if (self.step2View.model.attributes.primary_dimension_type == "None") {
					self.model.set({primaryDimensions: $('.primary-select').val()});
				}

				if (self.step2View.model.attributes.secondary_dimension_type == "All") {
					self.model.set({secondaryDims: $('.secondary-select').val()});
				}
				self.setComputation();
				self.step4View.render();
				self.setFormat();
			}
		});
	},

	setComputation: function() {
		var self = this;

		var modelJson = this.model.toJSON();

		$.ajax({
			dataType: "json",
			url: '/api/reports/post-process/computations/' + modelJson.orgId,
			success: function (data) {
				MarketReportsApp.Variables.Computations = data;
				self.step3View.render();
			}
		});
	},

	updateName: function() {
		if (this.model.isNew()) {

			// TODO safe name checks on each thing, set the results as values
			$('.ftp-directory-name').val(this.changeToSafeName($('.report-name').val())); // if new report, when the user types in report name, also pop that into ftp dir name field as default value
			$('.client-file-name').val(this.changeToSafeName($('.report-name').val())); // if new report, when the user types in report name, also pop that into file dir name field as default value


			// $('.ftp-directory-name').val($('.report-name').val()); // if new report, when the user types in report name, also pop that into ftp dir name field as default value
			// $('.client-file-name').val($('.report-name').val()); // if new report, when the user types in report name, also pop that into file dir name field as default value

			this.uniqueFTPDirectoryCheck(); // call this so that the unique name check runs
			this.updateFileName();
		}
		this.model.set({name: $('.report-name').val()});
	},

	changeToSafeName: function(unsafe) {
		let regex = /[^\w\d\-_]/g;
		return unsafe.replace(regex, '_')
	},

	updateFormat: function() {
		this.model.set({format: $('.format').val()});
		this.step3View.render(); // render again here since the format determines whether include primary dim checkbox should display

		// If Parquet is selected then the File Compression needs to be turned off.
		// It is already a compressed format. You get no savings there.
		if (this.model.get('format') === "parquet") {
			$('.compression_type_select').attr('disabled', true);
			$('.compression_type_select').val('none').change();
			$('.compression-type-select label span').attr('hidden', false);
		}
		else {
			$('.compression_type_select').attr('disabled', false);
			$('.compression-type-select label span').attr('hidden', true);
		}
	},

	updateOrg: function(e) {
		if ($(e.target).val().constructor === Array) {
			this.model.set({ orgId: $(e.target).val() });
		} else {
			var org = [];
			org.push($(e.target).val());
			this.model.set({ orgId: org });
		}
		MarketReportsApp.Utils.updateDimensionList(MarketReportsApp.Variables.primaryModel, this.model.get("orgId")[0]);
	},

	updatePrimaryDims: function() {
		if ($('.primary-select-all').is(':checked') == true) {
			this.model.set({primaryDimensions: "all"})
		} else {
			this.model.set({primaryDimensions: $('.primary-select').val()});
		}
	},

	updateSecondaryDims: function() {
		if ($('.secondary-select-all').is(':checked') == true) {
			//this is set up different from primary dims because of reasons....
			//see Market Report Controller, create Composite function.
			this.model.set({secondaryDimensions: [{secondary_dimension_value:"all"}]});
		} else {
			var secondaryDims = $('.secondary-select').val();
			var val = [];
			secondaryDims.forEach(function (item, index) {
				val.push({ secondary_dimension_value: item });
			});
			this.model.set({secondaryDimensions: val});
		}
	},

	updateRange: function() {

		this.model.set({rangeType: $('.range-type').val()});
		if (this.model.get('rangeType') === "absolute") {
			$('.start-date').addClass('required').change();
			$('.last-num-periods-start').removeClass('required');
			$('.last-num-periods-start').val(null);
		} else {
			$('.last-num-periods-start').addClass('required');
			$('.start-date').removeClass('required');
			$('.end-date').val(null);


		}
	},

	updateDates: function() {
		if (this.model.get('rangeType') === "absolute") {
			var startVal = $('.start-date').val();
			var startDateString = null;
			if (startVal) {
				var start = new Date(startVal);
				startDateString = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate();
			}

			var endVal = $('.end-date').val();
			var endDateString = null;
			if(endVal) {
				var end = new Date(endVal);
				endDateString = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate();
			}

			this.model.set({
				aggregate_period_start: startDateString,
				aggregate_period_end: endDateString,
				last_num_aggregate_periods_start: null,
				last_num_aggregate_periods_end: null
			})
		} else {
			this.model.set({
				aggregate_period_start: null,
				aggregate_period_end: null,
				last_num_aggregate_periods_start: $('.last-num-periods-start').val(),
				last_num_aggregate_periods_end: $('.last-num-periods-end').val()
			})
		}
	},

	updateSchedule: function() {
		this.model.set({ scheduler: $('.schedule-select').val()});
	},

	updateScheduleOption: function() {

		if($('input[name="last-day-checkbox"]:checked').length > 0){
			$('.day-value-select').val('').prop('disabled', true);
			this.model.set({ schedulerOption: 'last'});
		} else {
			$('.day-value-select').prop('disabled', false);
			var selectedValue = document.getElementsByClassName("day-value-select")[0].value;
			this.model.set({ schedulerOption: selectedValue});
		}

	},

	updateUsers: function() {
		this.model.set({ notifyUsers: $('.user-select').val()});
	},

	updatePostProcessSchedule: function() {
		this.model.get("postProcessReport").scheduler = $('.post-process-schedule-select').val();

	},

	updatePostProcessScheduleOption: function() {
		if($('input[name="post-process-last-day-checkbox"]:checked').length > 0){
			$('.post-process-day-value-select').val('').prop('disabled', true);
			this.model.get("postProcessReport").schedulerOption = 'last';
		} else {
			$('.post-process-day-value-select').prop('disabled', false);
			var selectedValue = document.getElementsByClassName("post-process-day-value-select")[0].value;
			this.model.get("postProcessReport").schedulerOption = selectedValue;
		}
	},

	updatePostProcessUsers: function() {
		this.model.postProcessReport.set({ sendToUsers: $('.post-process-user-select').val()});
	},


	updateSkipPostProcessing: function() {
		this.model.set({ skipPostProcessing: $('.skip-post-processing').is(':checked')})
	},


	updatePrimaryList: function() {
		var self = this;

		if ($('.primary-list-set').is(':checked')) {
			self.model.set({ primaryDimensions: 'list' });
			self.model.set({primary_dimension_value_list_id: $('.primary-dimension-lists').val() });
			$('.primary-select-all').removeClass('required');
			$('.primary-select').removeClass('required');
			$('.primary-dimension-lists').addClass('required');
		} else {
			self.model.set({ primaryDimensions: [] });
			this.updatePrimaryDims();
			self.model.set({primary_dimension_value_list_id: null});
			$('.primary-select').addClass('required');
			$('.primary-dimension-lists').removeClass('required');
		}
	},
	updatePrimaryListSelection: function() {
		this.model.set({ primary_dimension_value_list_id: $('.primary-dimension-lists').val() });
	},

	updateSecondaryList: function() {
		var self = this;
		if ($('.secondary-list-set').is(':checked')) {
			self.model.set({ secondaryDimensions: [{secondary_dimension_value: 'list'}] });
			self.model.set({ secondary_dimension_value_list_id: $('.secondary-dimension-lists').val() });
			$('.secondary-select-all').removeClass('required');
			$('.secondary-select').removeClass('required');
			$('.secondary-dimension-lists').addClass('required');
		} else {
			self.model.set({ secondaryDimensions: [] });
			this.updateSecondaryDims();
			self.model.set({ secondary_dimension_value_list_id: null });
			$('.secondary-select').addClass('required');
			$('.secondary-dimension-lists').removeClass('required');
		}
	},

	updateSecondaryListSelection: function() {
		this.model.set({ secondary_dimension_value_list_id: $('.secondary-dimension-lists').val() });
	},

	updateStandard: function() {
		this.model.set({ is_standard: $('.is-standard').is(':checked') });
		$('.org-select').toggleClass('required');
		$('.is-standard-display').toggleClass('hidden');
		this.uniqueFTPDirectoryCheck();
	},

	setCompressionType: function () {
		this.model.set({compression_type: $('.compression_type_select').val()});
	},

	setFormat: function () {
		this.model.set({format: $('.format').val()});
		if (this.model.get('format') === "parquet") {
			$('.compression_type_select').attr('disabled', true);
			$('.compression_type_select').val('none').change();
			$('.compression-type-select label span').attr('hidden', false);
		}
	},

	setWrapSingleFile: function () {
		if ($('.wrap-single-file-checkbox').is(":checked")) {
			if (this.model.get("compression_type") === "gzip") {
				toastr["error"]("Reports that generate single files cannot be wrapped in a gzipped directory. Please uncheck this setting or choose zip for compression type.");
				return false;
			}
			this.model.set({wrap_single_files_in_directory: true});
		} else {
			this.model.set({wrap_single_files_in_directory: false});
		}
		return true;
	},

	checkNumberOfFiles: function() {
		if (this.model.get("compression_type") == "none") {
			if (this.model.get("format") == "single_sheet_csv" ||
				this.model.get("format") == "single_sheet_pivoted_csv" ||
				this.model.get("format") == "excel" ||
				this.model.get("format") == "parquet" ||
				this.step2View.model.attributes.primary_dimension_type == "None" ||
				this.model.get("primaryDimensions").length === 1) {
				return true;
			}
			toastr["error"]("Reports that generate more than one file must be compressed. Please return to step 1 and select zip or gzip for file compression.");
			return false; // the situations in the if clause above are the only ones that produce single file reports, therefore only situtations for which none compression should be allowed
		}
		return true;
	},

	setValueAxis: function(e) {
		this.model.set({ value_axis: e.target.value });
	},

	updateDescription: function() {
		this.model.set({description: $('.report-description').val()});
	},

	updateFileName: function() {
		this.model.set({client_file_name: $('.client-file-name').val()});
	},

	updateFTPDirectoryName: function() {
		if (MarketReportsApp.Variables.EditReport != null) {
			if ($('.ftp-directory-name').val() != MarketReportsApp.Variables.EditReport.reportComposite.report.ftp_directory_name) {
				$('#rename-settings').modal('toggle');
			}
		}
		this.uniqueFTPDirectoryCheck();
	},

	uniqueFTPDirectoryCheck: function () {
		let self = this;
		fetch("/api/reports/unique-ftp-name", {
			method: 'POST',
			body: JSON.stringify({ftp_directory_name: $('.ftp-directory-name').val(), org_id: self.model.get("orgId")[0], is_standard: self.model.get("is_standard")}),
		}).then(response => response.json())
			.then(function(response) {
				if (response.status !== 200) {
					$('.report-name').val(null);
					toastr["error"]("Error checking for unique FTP directory name. Cannot build report.");
				}
				if (response.isUnique) {
					self.model.set({ftp_directory_name: $('.ftp-directory-name').val()});
				} else {
					$('.ftp-directory-name').val("");
					toastr["error"]("A report with this FTP Directory name already exists for this organization, or for standard reports. \n Please give the report a unique FTP directory name before proceeding.");
				}
			})
	},

	updateDeleteLocked: function(e) {
		this.model.set({ delete_locked: $(e.target).is(':checked') });
	}
});