FeedsApp.Views.FeedBuilderWizardView = Backbone.View.extend({

    events: {
        "click .is-standard": "updateStandard",
        "change [name='format']": "updateFormat"
    },

    initialize: function () {
        var self = this;
        $.ajax({
            url: '/assets/js/feeds/feed-builder/templates/wizard.html',
            success: function (extTemplate) {
                self.template = _.template(extTemplate);
                $('.feed-builder-app-container').append(self.$el);
                self.render();
            },
            dataType: 'html',
            cache: false
        });
    },

    render: function () {

        this.$el.html(this.template());
        this.step1 = new FeedsApp.Views.FeedBuilderStep1({model: this.model});
        this.step2 = new FeedsApp.Views.FeedBuilderStep2({model: this.model});
        this.step3 = new FeedsApp.Views.FeedBuilderStep3({model: this.model});
        this.step4 = new FeedsApp.Views.FeedBuilderStep4({model: this.model});
        this.step5 = null;
        this.step6 = new FeedsApp.Views.FeedBuilderStep6({model: this.model});
        this.steps = [this.step1, this.step2, this.step3, this.step4, this.step5, this.step6];

        var self = this;
        $('#wizard').steps({
            bodyTag: 'section',
            enableCancelButton: false,
            stepsOrientation: "vertical",
            enableCancelButton: true,

            onStepChanging: function (event, currentIndex, newIndex) {
                var sel = document.getElementsByName("format")[0];
                var chosenFormatDiv = document.getElementById('format-message');

                if (sel.options[sel.selectedIndex].text === 'xml') {
                    chosenFormatDiv.innerHTML = '<p>Chosen feed format: <i>' + sel.options[sel.selectedIndex].text + '</i>. Field name overrides for XML <span class="text-danger">cannot contain spaces</span>.</p>';
                } else {
                    chosenFormatDiv.innerHTML = '';
                }

                // Always allow going backward even if the current step contains invalid fields!!!!!!!!!!!!!!!!!!!!!!
                if (currentIndex > newIndex) {
                    return true;
                }

                // Once Feed Type is set, create step5 to ensure organic filters will be displayed
                if (currentIndex === 1) {
                    if (self.step5 === null) {
                        self.step5 = new FeedsApp.Views.FeedBuilderStep5({model: self.model});
                        self.steps[4] = self.step5;
                    }
                }

                if (currentIndex === 2) {
                    if (self.model.get('job_fields').length === 0 && self.model.get('mapped_fields').length === 0) {
                        toastr["warning"]("You need at least 1 job field to continue.");
                        return false;
                    }

                    var unNamedExtended = self.model.get('job_fields').find(function (field) {
                        return (field.internal_name === "extended" && !field.extended_field_name);
                    });

                    if (unNamedExtended) {
                        toastr["warning"]("You need to name the extended field to continue.");
                        return false;
                    }
                }

                if (newIndex === 3) {
                    if (self.model.get('template').get('id')) {
                        self.step4.previewWindowView.render();
                    }
                }

                if (currentIndex === 4) {
                    var filterError = false;
                    var sFilterName = '';
                    var sCondition = '';
                    var sFilteValues = [];
                    var errorMsg = 'You need at least 1 filter to continue.';

                    if (self.step5.model.attributes.feed_filters.length === 0) {
                        toastr["warning"](errorMsg);
                        return false;
                    }

                    if(self.step5.model.attributes.feed_filters.length > 0) {
                        var secondaryFilterNames = [];
                        var secondaryConditions = [];
                        var secondaryFilterValues = [];

                        $('.secondary-internal-id').each(function() {
                            secondaryFilterNames.push($(this).val());
                        });

                        $('.secondary-condition-type').each(function() {
                            secondaryConditions.push($(this).val());
                        });

                        $('.secondary-value').each(function() {
                            secondaryFilterValues.push($(this).val());
                        });
                        
                        $.each( self.step5.model.attributes.feed_filters.models, function( key, value ) {
                           //primary values   
                            if(value.attributes.primary_value.length === 0){
                                filterError = true;
                                errorMsg = 'You need at least 1 filter value for '+value.attributes.primary_available_field.attributes.internal_name+" to continue.";
                                return false;
                            }

                            //Optional Filter Values
                            sFilterName = secondaryFilterNames[key];
                            sCondition = secondaryConditions[key];
                            sFilteValues =  secondaryFilterValues[key];

                            if(sFilterName || sCondition || sFilteValues.length>0){

                                if(!sFilterName){
                                    filterError = true;
                                    errorMsg = 'You need to select 1 optional filter for '+value.attributes.primary_available_field.attributes.internal_name+" to continue.";
                                    return false;
                                }

                                if(!sCondition){
                                    filterError = true;
                                    errorMsg = 'You need to select 1 optional condition for '+value.attributes.primary_available_field.attributes.internal_name+" to continue.";
                                    return false;
                                }

                                if(sFilteValues.length<1){
                                    filterError = true;
                                    errorMsg = 'You need at least 1 filter value for '+value.attributes.primary_available_field.attributes.internal_name+" to continue.";
                                    return false;
                                }
                            }
                        });

                        if(filterError){
                            toastr["warning"](errorMsg);
                            return false;
                        }
                    }
                }

                var form = $(this);

                // Clean up if user went backward before
                if (currentIndex < newIndex) {
                    // To remove error styles
                    $(".body:eq(" + newIndex + ") label.error", form).remove();
                    $(".body:eq(" + newIndex + ") .error", form).removeClass("error");
                }

                // Disable validation on fields that are disabled or hidden.
                form.validate().settings.ignore = ":disabled,:hidden";

                // Start validation; Prevent going forward if false
                return form.valid();
            },

            onFinishing: function (event, currentIndex) {
                var form = $(this);
                // Disable validation on fields that are disabled.
                // At this point it's recommended to do an overall check (mean ignoring only disabled fields)
                form.validate().settings.ignore = ":disabled";

                // Start validation; Prevent form submission if false
                return form.valid();
            },

            onFinished: function (event, currentIndex) {
                // Submit backbone Model
                self.submitFeed();
            },

            onCanceled: function (event) {
                // redirect to feed page
                self.cancelClickRedirection(self.model.attributes.id);
            }
        }).validate({
            errorPlacement: function (error, element) {
                if ((element.hasClass('primary-condition') && !element.hasClass('primary-condition-type')) || (element.hasClass('optional-condition')) && !element.hasClass('secondary-condition-type')) {
                    element.parents('.col-lg-12').find('.error-container').prepend(error);
                } else {
                    element.before(error);
                }
            }
        });

        //Add the pagination button in top
        self.clonePaginationBtnToTop(self); 

        //Cancel Label customize
        self.cancelLabel();

        setInterval(function () {
            resizeJquerySteps()
        }, 250);
    },

    updateStandard: function () {
        this.model.set({is_standard: $('.is-standard').is(':checked')});
        $('.org-select').toggleClass('required');
        $('.is-standard-display').toggleClass('hidden');
    },

    updateFormat: function () {
        var sel = document.getElementsByName("format")[0];
        var chosenFormatDiv = document.getElementById('format-message');

        if (sel.options[sel.selectedIndex].text === 'parquet') {
            $('.compression-select').attr('disabled', true);
            $('.compression-select').val('none').change();
            $('.compression-type-select label span').attr('hidden', false);
            if (chosenFormatDiv !== null) {
                chosenFormatDiv.innerHTML = '';
            }
        } else if (sel.options[sel.selectedIndex].text === 'xml') {
            $('.compression-select').attr('disabled', false);
            $('.compression-type-select label span').attr('hidden', true);
            if (chosenFormatDiv !== null) {
                chosenFormatDiv.innerHTML = '<p>Chosen feed format: <i>' + sel.options[sel.selectedIndex].text + '</i>. Field name overrides for XML <span class="text-danger">cannot contain spaces.</span></p>';
            }
        } else {
            $('.compression-select').attr('disabled', false);
            $('.compression-type-select label span').attr('hidden', true);
            if (chosenFormatDiv !== null) {
                chosenFormatDiv.innerHTML = '';
            }
        }
    },

    submitFeed: function () {
        $('.saving').toggleClass('hidden');
        $('a[href$="#finish"]').addClass('disabled');

        this.model.save(null, {
            success: function (model, response) {
                window.location = '/feeds/details/' + response.feed_id;
            },
            error: function (model, response) {
                toastr["error"](response.responseJSON.message, response.responseJSON.status);
                $('a[href$="#finish"]').removeClass('disabled');
            }
        });

    },

    cancelLabel : function(){
        //apply the css to cancel button
        if($('a[href$="#cancel"]').length>0){
            //Add class to li
            $('a[href$="#cancel"]').parent().addClass('cancel');
        }
    },

    clonePaginationBtnToTop: function(el){ 
        //prepend pagination buttons to top
        $('#wizard').prepend($('#wizard .actions').clone().addClass("top-pagination"));

        //click events for top pagination button
        el.$("#wizard").on("click", ".top-pagination a[role='menuitem']", function(tp) {
            tp.preventDefault();

            if($(tp).length>0){
                //Trigger steps 
                var tpPaginationTarget = $.trim($(tp)[0].currentTarget.innerText.toLowerCase());
                switch(tpPaginationTarget){
                    case 'next':
                        $("#wizard").steps("next");
                    break;
                    case 'previous':
                        $("#wizard").steps("previous");
                    break;
                    case 'finish':
                        $("#wizard").steps("finish");
                    break;
                    case 'cancel':
                        // redirect to feed page
                        this.cancelClickRedirection(el.model.attributes.id);
                    break;
                }
            }
            
        }.bind(el));
    },

    cancelClickRedirection: function(modelId){ 
        if(modelId !== null){ 
            window.location.href = '/feeds/details/'+modelId;
        }else{
            window.location.href = '/feeds';
        }
    } 
});