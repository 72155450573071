FeedsApp.Models.FeedModel = FeedsApp.Models.FeedsBase.extend({

    // Put the uri inside the quotes
    urlRoot: '/feeds/feed-builder/save',

    defaults: function () {
        return {
            id: null,
            name: null,
            description: null,
            random_sort: false,
            generate_differential: false,
            client_file_name: 'feed',
            is_standard: false,
            job_limit: null,
            zip_type: 'gzip',
            user_id: null,
            feed_type: null,
            template: new FeedsApp.Models.FeedsBase({
                id: null,
                name: null,
                template_definition: null,
                job_template_definition: null
            }),
            org_associations: new FeedsApp.Collections.OrgAssociations(),
            format: 'xml',
            feed_filters: new FeedsApp.Collections.FeedFilters(),
            job_fields: new FeedsApp.Collections.FeedFields(),
            structured_fields: new FeedsApp.Collections.FeedFields(),
            global_fields: [],
            mapped_fields: new FeedsApp.Collections.FeedFields(),
            feed_types: [],
            feed_schedules: new FeedsApp.Collections.ScheduledFeeds(),
            distribution_channels: [],
        }
    },

    initialize: function () {

        if (this.id == null) {
            var default_fields = ['title', 'company_name'];
            var model_feed_fields = [];

            _.each(default_fields, function (default_field) {
                var feed_field = new FeedsApp.Models.FeedField();
                feed_field.set('field_type', 'job');
                var preConvertedField = _.find(FeedsApp.Variables.JobFieldList, function (field) {
                    return field.internal_name == default_field;
                });

                feed_field.set('data_type', preConvertedField.data_type);
                feed_field.set('field_type_id', preConvertedField.field_type_id);
                feed_field.get('available_field').set('internal_field_id', preConvertedField.id);
                feed_field.get('available_field').set('internal_name', preConvertedField.internal_name);

                model_feed_fields.push(feed_field);
            });
            this.set({job_fields: new FeedsApp.Collections.FeedFields(model_feed_fields)});
        }
    },

    parse: function (data) {
        // If any mapped feed fields exist, merge them into the job fields array and create the collection from that.
        // That way, Step 3 can loop over one collection rather than two to render the sub-views, and their sub-models will remain intact.
        if (data.mapped_fields !== undefined) {
            var combinedFields = data.job_fields.concat(data.mapped_fields);
            data.job_fields = new FeedsApp.Collections.FeedFields(combinedFields, {parse: true});
            //this is needed for consistency with the default model. @TODO figure out if we can get rid of the mapped_fields property on the FE model.
            data.mapped_fields = new FeedsApp.Collections.FeedFields();
        } else {
            data.job_fields = new FeedsApp.Collections.FeedFields(data.job_fields, {parse: true});
        }
        data.org_associations = new FeedsApp.Collections.OrgAssociations(data.org_associations, {parse: true});
        data.feed_filters = new FeedsApp.Collections.FeedFilters(data.feed_filters, {parse: true});
        data.feed_schedules = new FeedsApp.Collections.ScheduledFeeds(data.feed_schedules, {parse: true});
        data.template = new FeedsApp.Models.FeedsBase(data.template, {parse: true});
        return data;
    }
});