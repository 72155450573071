MarketReportsApp.Views.AggregateEntryView = Backbone.View.extend({

    tagName: 'tr',

    events: {
        "click .view-all-reports": "viewReportsByAggregate",
        "click .view-scheduled-reports": "viewScheduledReportsByAggregate",
        "click .aggregate-toggle": "toggleAggregateStatus",
        "click .confirm-request": "sendRequest"
    },

    initialize: function() {

        let self = this;
        this.model.attributes.scheduledReports = _.reject(this.model.attributes.reports, function(report) {
            return report.isScheduled === false;
        });

        $('.aggregates-table-data').append(this.$el);
        self.render();
        $('.org-select').select2({
            placeholder: user.attributes.orgName,
            ajax: {
                url: function(params) {
                    return "/api/users/organization/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });

    },

    render: function() {
        this.$el.html(MarketReportsApp.Templates.AggregateEntryRow(this.model.toJSON()));
    },

    viewReportsByAggregate: function(e) {

        $('#reports-tables').removeClass('hidden');

        $('#aggregate-to-scheduled-reports-table').addClass('hidden');
        $('#aggregate-to-reports-table').removeClass('hidden');

        let self = this;
        document.getElementById("aggregate-name").textContent = this.model.get('name');
        $('.click-status').removeClass('badge');
        $(e.target).addClass('badge');
        let reportsData = this.model.get('reports');

        $('.reports.table').DataTable().clear().destroy();
        reportsData.forEach(self.addReport, this);
        $('#aggregate-to-reports-table').DataTable();
    },

    addReport: function(reportData) {

        let reportsTable = document.getElementById('aggregate-to-reports-table').getElementsByTagName('tbody')[0];
        let newRow = reportsTable.insertRow();

        let reportName = newRow.insertCell(0);
        let a = document.createElement('a');
        let reportNameValue = document.createTextNode(reportData.reportName);
        a.appendChild(reportNameValue);
        a.href = 'details/' + reportData.reportId;
        reportName.appendChild(a);

        let reportUser = newRow.insertCell(1);
        let reportUserNameValue = document.createTextNode(reportData.userName);
        reportUser.appendChild(reportUserNameValue);
    },

    viewScheduledReportsByAggregate: function(e) {

        $('#reports-tables').removeClass('hidden');

        $('#aggregate-to-reports-table').addClass('hidden');
        $('#aggregate-to-scheduled-reports-table').removeClass('hidden');

        let self = this;
        document.getElementById("aggregate-name").textContent = this.model.get('name');
        $('.click-status').removeClass('badge');
        $(e.target).addClass('badge');
        let scheduledReportsData = this.model.get('scheduledReports');

        $('.reports.table').DataTable().clear().destroy();
        scheduledReportsData.forEach(self.addScheduledReport, this);
        $('#aggregate-to-scheduled-reports-table').DataTable();
    },

    addScheduledReport: function(reportData) {

        let reportsTable = document.getElementById('aggregate-to-scheduled-reports-table').getElementsByTagName('tbody')[0];
        let newRow = reportsTable.insertRow();

        let reportName = newRow.insertCell(0);
        let a = document.createElement('a');
        let reportNameValue = document.createTextNode(reportData.reportName);
        a.appendChild(reportNameValue);
        a.href = 'details/' + reportData.reportId;
        reportName.appendChild(a);

        let lastRun = newRow.insertCell(1);
        let lastRunValue = document.createTextNode(moment(reportData.isScheduled.last_completed_time).local().format('M/D/YYYY h:mm A ') + moment.tz(moment.tz.guess()).format('z'));
        lastRun.appendChild(lastRunValue);

        let frequency = newRow.insertCell(2);
        let frequencyValue = document.createTextNode(reportData.isScheduled.frequency);
        frequency.appendChild(frequencyValue);

        let reportUser = newRow.insertCell(3);
        let reportUserNameValue = document.createTextNode(reportData.userName);
        reportUser.appendChild(reportUserNameValue);
    },

    toggleAggregateStatus: function(e) {
        let self = this;

        let statusText = self.$('.status').text().trim(); // gets the current status ("On", "Off" or "Pending")

        if (statusText === 'Off') {
            // clear the modal body text/content
            self.$('.modal-body').empty();

            // if status is "Off", show You are enabling confirmation message
            self.$('.modal-body').append('You are enabling aggregate ' +'<strong>' + self.model.get('name') + '</strong>' + '<br>' + 'Aggregate will be activated and available for use after EMR has run. This may be at least 24 hours.');
        } else if (statusText === 'On') {

            // clear the modal body text/content
            self.$('.modal-body').empty();

            // if status is "On", show the activation message;
            self.$('.modal-body').append('Are you sure you want to disable this aggregate?: ' + '<br>' + '<strong>' + self.model.get('name') + '</strong>');
        } else if (statusText === 'Pending') {

            // clear the modal body text/content
            self.$('.modal-body').empty();

            // if status is "Pending"
            self.$('.modal-body').append('Are you sure you want to disable this aggregate: ' + '<br>' + '<strong>' + self.model.get('name') + '</strong>' + '<br>' + 'This aggregrate is ' + '<i>' + statusText + '</i>' + ' and queued to be turned on. Aggregate will be activated and available for use after EMR has run. This may be at least 24 hours.');
        }
    },

    sendRequest: function(e) {

        let self = this;

        if(e.target.name === 'is_active_1'){
            self.$('.status').text('Off');
            self.$('.aggregate-toggle').text('On');
            self.$('.dropdown-toggle').removeClass('btn-primary');
        } else {
            self.$('.status').text('Pending');
            self.$('.aggregate-toggle').text('Off');
            self.$('.dropdown-toggle').addClass('btn-primary');
        }

        let isActive = +!parseInt(self.model.get('status').is_active);

        let aggregationStatus = new MarketReportsApp.Models.AggregationStatus(
            {
                aggregationScheduleId: self.model.get('id'),
                isActive: isActive
            });

        aggregationStatus.save(null, {
            success: function () {
                let alertMsg = e.target.name === 'is_active_1' ? "off." : "on.";
                toastr["success"]("Aggregate queued to be turned " + alertMsg);
            },
            error: function(model) {
                toastr["error"](model.responseJSON.message);
                if(e.target.name === 'is_active_1'){
                    self.$('.status').text('Pending');
                    self.$('.aggregate-toggle').text('Off');
                    self.$('.dropdown-toggle').addClass('btn-primary');
                } else {
                    self.$('.status').text('Off');
                    self.$('.aggregate-toggle').text('On');
                    self.$('.dropdown-toggle').removeClass('btn-primary');
                }
            }
        });
    }

});