FeedsApp.Views.FeedBuilderStep3 = Backbone.View.extend({

	events: {
		"click .add-global-field": "addGlobalField",
		"click .add-field": "addField",
	},

	initialize: function() {

		this.collection = this.model.get('job_fields');

        var self = this;
		$.ajax({
			url: '/assets/js/feeds/feed-builder/templates/step3.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();

				$.ajax({
                    url: '/assets/js/feeds/feed-builder/templates/field-customize.html',
                    success: function(extTemplate) {
                        FeedsApp.Templates.FieldCustomizeTemplate = _.template(extTemplate);
                        self.collection.each(function(field) {
                            new FeedsApp.Views.FieldCustomize({ model: field });
                        });
                    },
                    dataType: 'html',
                    cache: false
                });
			},
			dataType: 'html',
            cache: false
		});

	},

	render: function() {
		this.$el.html(this.template());
		$('#step3').append(this.$el);

        // Initialise sortable for job fields
        $('.job-customization').sortable({
			update: updateFieldOrder,
			cursor: 'move',
		});

		//need this for editing, has to match step 2 logic :(
		this.feedTypeCheck();
		return this;
	},

    feedTypeCheck:function() {
        let selectedFeedType = $('.feed-type-select option:selected').text().trim();
        if (selectedFeedType === "Historical") {
            $('.field-historical-only').removeClass('disabled');
            $('.field-not-historical').addClass('disabled');
        } else {
            $('.field-historical-only').addClass('disabled');
            $('.field-not-historical').removeClass('disabled');
        }
    },

	addGlobalField: function() {
		if ($('.global-fields').val() !== "") {
            var fieldData = FeedsApp.Variables.GlobalFieldList.filter(function(field) {
                var val = $('.global-fields').val();
            	return field.id === val;
            })[0];
            fieldData.field_type = 'global';
            fieldData.internal_field_id = fieldData.id;

            var globalField = new FeedsApp.Models.FeedField(fieldData);
            globalField.set('id', null);
            this.collection.add(globalField);

			new FeedsApp.Views.FieldCustomize({ model: globalField });
		}
	},

    addField: function(e) {

        var feedField = new FeedsApp.Models.FeedField();

        var fieldType = FeedsApp.Variables.FieldTypeList.filter(function(field) {
            return field.name === 'job';
        })[0];

        feedField.set({
            field_type: 'job',
            field_type_id: fieldType.id,
            available_field: new FeedsApp.Models.FeedAvailableField({
                    'internal_field_id': $(e.target).data('id'),
                    'internal_name': $(e.target).text()
            })
        });

        this.collection.add(feedField);

        new FeedsApp.Views.FieldCustomize({ model: feedField, parent: this.model, jobFieldCollection: this.collection });

        // trigger field order update on add field button
        updateFieldOrder();
    }

});