ContinuousDeliveryMetricsApp.Models.BreakerReasonCodes = Backbone.Model.extend({
    
    url: function() {
        return "/api/continuous-delivery-metrics/breaker/reason-codes"
    },
    
    defaults: {
        breakerReasonCodes: ['initial state']
    },

    parse: function (data) {
        let model = {};
        model.breakerReasonCodes = {};
        if (data.length === 0) {
            return model;
        }
        let labels = [];
        let metrics = [];
        for (let i = 0; i < data.length; i++) {
            labels.push(data[i].reason_code);
            metrics.push(data[i].count);
        }
        model.breakerReasonCodes.labels = labels;
        model.breakerReasonCodes.metrics = metrics;
        return model;
    }
});