MarketReportsApp.Views.AggregateRegistryView = Backbone.View.extend({

    events: {
        "change .org-select": "updateView",
        "click .view-all-aggregates": "updateView"
    },

    initialize: function () {

        let self = this;

        $.ajax({
            url: '/assets/js/market-reports/aggregate-registry/templates/aggregate-registry.html',
            success: function (extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache: false
        });
    },

    render: function () {

        this.$el.html(this.template(this.collection.toJSON()[1]));
        $('.aggregate-registry-container').append(this.$el);

        this.renderRows();
    },

    renderRows: function () {

        var self = this;

        $.ajax({
            url: '/assets/js/market-reports/aggregate-registry/templates/aggregate-entry-row.html',
            success: function (extTemplate) {

                MarketReportsApp.Templates.AggregateEntryRow = _.template(extTemplate);
                self.collection.forEach(self.addOne, this);
                $('#aggregates-table').DataTable({
                    pageLength: 10,
                    order: [],
                    language: {
                        "emptyTable": "No aggregates found for this organization."
                    }
                });
                $('.aggregate-registry-container').removeClass('sk-loading');
            },
            dataType: 'html',
            cache: false
        })
    },

    addOne: function (aggregateEntryModel) {
        new MarketReportsApp.Views.AggregateEntryView({ model: aggregateEntryModel, parent: this });
    },

    updateView: function(e) {

        let self = this;

        let orgId = e.target.checked ? 'all' : user.get('orgId');

        MarketReportsApp.Utils.updateSelectedOrg(this.collection.orgId, this.collection.orgName);

        $('.aggregate-registry-container').addClass('sk-loading');

        this.collection.fetch({
            reset: true,
            data: {orgId},
            success: function () {
                self.render();
            },
            error: function () {
                $('#aggregates-table').DataTable().clear().draw();
                $('#reports-tables').addClass('hidden');
                $('.aggregate-registry-container').removeClass('sk-loading');
            }
        });
    }

});