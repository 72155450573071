MarketReportsApp.Models.ReportBuilderModel = Backbone.Model.extend({

    url: "/market-reports/report-builder",

    defaults: {
        aggregateIds: [],
        aggregateName: null,
        aggregate_period_start: null,
        aggregate_period_end: null,
        description: null,
        format: 'csv',
        last_num_aggregate_periods_start: null,
        last_num_aggregate_periods_end: null,
        name: null,
        notifyUsers: [],
        orgId: [],
        postProcessReport: {
            appended_dims: null,
            reports_post_process_files: null,
            scheduler: null,
            schedulerOption: null,
            sendToUsers: [],
            include_primary_dimension_in_rows: 0,
            with_headers: false
        },
        primaryDimensions: [],
        rangeType: 'absolute',
        scheduler: 'noSchedule',
        schedulerOption: null,
        secondaryDimensions: [],
        value_axis: 'default',
        skipPostProcessing: false,
        is_standard: false,
        primary_dimension_value_list_id: null,
        secondary_dimension_value_list_id: null,
        user_id: null,
        compression_type: null,
        wrap_single_files_in_directory: null,
        client_file_name: null,
        ftp_directory_name: null,
        delete_locked: false,
        distribution_channels: [],
    },

    parse: function(data, options) {

        var postProcessReport = {};
        var skipPostProcessing = true;
        if (data.postProcess !== null) {
            postProcessReport = {
                appended_dims: data.postProcess.appended_dims,
                reports_post_process_files: data.postProcess.reports_post_process_files,
                scheduler: null,
                schedulerOption: null,
                sendToUsers: [],
                stats: data.postProcess.stats,
                with_headers: data.postProcess.reports_post_process_files.with_headers,
            };
            skipPostProcessing = false;
        }

        var convertedModel = {
            id: data.reportComposite.report.id,
            aggregateIds: data.aggregationScheduleIds,
            aggregateName: data.aggregationScheduleName,
            name: data.reportComposite.report.name,
            description: data.reportComposite.report.description,
            format: data.reportComposite.report.format,
            is_standard: data.reportComposite.report.is_standard,
            orgId: data.reportComposite.org_associations,
            aggregate_period_start: data.reportComposite.sheets[0].reportTable.aggregate_period_start,
            aggregate_period_end: data.reportComposite.sheets[0].reportTable.aggregate_period_end,
            last_num_aggregate_periods_start: data.reportComposite.sheets[0].reportTable.last_num_aggregate_periods_start,
            last_num_aggregate_periods_end: data.reportComposite.sheets[0].reportTable.last_num_aggregate_periods_end,
            primaryDimensions: [],
            secondaryDimensions: [],
            skipPostProcessing: skipPostProcessing,
            postProcessReport: postProcessReport,
            primary_dimension_value_list_id: data.reportComposite.report.primary_dimension_value_list_id,
            secondary_dimension_value_list_id: data.reportComposite.report.secondary_dimension_value_list_id,
            user_id: data.reportComposite.report.user_id,
            compression_type:  data.reportComposite.report.compression_type,
            wrap_single_files_in_directory:  data.reportComposite.report.wrap_single_files_in_directory,
            value_axis: data.reportComposite.report.value_axis,
            client_file_name:  data.reportComposite.report.client_file_name,
            ftp_directory_name:  data.reportComposite.report.ftp_directory_name,
            delete_locked:  data.reportComposite.report.delete_locked,
            distribution_channels: data.reportComposite.report.distribution_channels,
        };

        if (convertedModel.aggregate_period_start != null) {
            convertedModel.rangeType = "absolute"
        } else {
            convertedModel.rangeType = "relative"
        }

        if (data.reportComposite.sheets[0].aggregateTable.primary_dimension_value != "None" && data.reportComposite.sheets[0].aggregateTable.primary_dimension_value != "All") {
            var primaryDimType = data.reportComposite.sheets[0].aggregateTable.primary_dimension_type_id;

            data.reportComposite.sheets.forEach(function(sheet) {
                convertedModel.primaryDimensions.push(sheet.aggregateTable.primary_dimension_value);
            });

//            MarketReportsApp.Variables.SelectedPrimaryDims = this.getDimNames(primaryDimType, convertedModel.primaryDimensions);
        } else if (data.reportComposite.sheets[0].aggregateTable.primary_dimension_value != "All") {
            convertedModel.primaryDimensions = "All";
        } else if (data.reportComposite.sheets[0].aggregateTable.primary_dimension_value != "None") {
            convertedModel.primaryDimensions = "None";
        }

        if (data.reportComposite.sheets[0].secondaryDimensions[0].secondary_dimension_value != "None" && data.reportComposite.sheets[0].secondaryDimensions[0].secondary_dimension_value != "All") {
            var secondaryDimType = data.reportComposite.sheets[0].aggregateTable.secondary_dimension_type_id;

            data.reportComposite.sheets[0].secondaryDimensions.forEach(function(row) {
                var secondaryValue = {
                    secondary_dimension_value: row.secondary_dimension_value
                };

                convertedModel.secondaryDimensions.push(secondaryValue);
            });

//            var valuesArray = $.map(convertedModel.secondaryDimensions, function(value, index) {return value});
//            MarketReportsApp.Variables.SelectedSecondaryDims = this.getDimNames(secondaryDimType, valuesArray);
        } else if (data.reportComposite.sheets[0].secondaryDimensions[0].secondary_dimension_value == "All") {
            var secondaryValue = {
                secondary_dimension_value: "all"
            };

            secondaryDimValues.push(secondaryValue);
        }

        return convertedModel;
    },

    getDimNames: function(dimType, dimValues) {
        $.ajax({
            type: 'POST',
            url: "/api/reports/dimensions/dimensionNameString/" + dimType,
            data: { dimensionValues: dimValues },
            success: function(response) {
                return response.dimensionNames
            }
        });
    }
});