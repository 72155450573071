ContinuousDeliveryMetricsApp.Views.BreakerReasonCodes = Backbone.View.extend({
    className: "col-lg-12",

    initialize: function() {
        var self = this;

        $('.breaker-reason-codes-ibox').append(this.$el);

        $.ajax({
          url: '/assets/js/admin/continuous-delivery-metrics/03_templates/breaker-reason-codes.html',
          success: function(extTemplate) {
            self.template = _.template(extTemplate);
            self.model.fetch();
            self.render();
          },
          cache: false
        });

        this.model.on('change', this.render, this);
    },

    render: function() {
        var model = this.model.toJSON();
        this.$el.html(this.template(model));

        if (model.breakerReasonCodes.length !== 1 || model.breakerReasonCodes[0] != 'initial state') {
            $('.breaker-reason-codes-ibox').toggleClass('sk-loading');
            // If the model is an empty object, do not set up the chart.
            if (Object.keys(model.breakerReasonCodes).length > 0) {
                this.updatePieChart(model);
            }
        }
    },

    updatePieChart: function(model) {
        let ctx=$("#breakerReasonCodes");

        const COLORS = [
            '#003F5C',
            '#58508D',
            '#BC5090',
            '#FF6361',
            '#FFA600',
            '#E6F69D',
            '#AADEA7',
            '#64C2A6',
            '#2D87BB',
            '#FFF1C9',
            '#F7B7A3',
            '#EA5F89',
            '#9B3192',
            '#57167E',
            '#2B0B3F'
        ];

        this.chart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: model.breakerReasonCodes.labels,
                datasets: [
                    {
                        data: model.breakerReasonCodes.metrics,
                        backgroundColor: COLORS
                    }
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Reason Codes for the last seven days'
                    }
                }
            }
        });
    },

    close: function() {
        this.remove();
        this.unbind();
    }
});