var ContinuousDeliveryMetricsApp = new (Backbone.Router.extend({
    Models: {},
    Views: {},
    Collections: {},
    Variables: {},
    Templates: {},

    routes: {
        "admin/cd-metrics": "startCdMetricsPage",
        "admin/cd-metrics-breaker": "startCdBreakerPage"
    },

    startCdMetricsPage: function() {
        var lastSevenDaysModel = new ContinuousDeliveryMetricsApp.Models.LastSevenDays();
        new ContinuousDeliveryMetricsApp.Views.LastSevenDays({ model: lastSevenDaysModel });

        new ContinuousDeliveryMetricsApp.Views.EntitySearch();
    },

    startCdBreakerPage: function() {
        var breakerSevenDayModel = new ContinuousDeliveryMetricsApp.Models.BreakerSevenDays();
        new ContinuousDeliveryMetricsApp.Views.BreakerSevenDays({model: breakerSevenDayModel});

        var breakerReasonCodesModel = new ContinuousDeliveryMetricsApp.Models.BreakerReasonCodes();
        new ContinuousDeliveryMetricsApp.Views.BreakerReasonCodes({model: breakerReasonCodesModel});
    }

}));